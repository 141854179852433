import React, { useEffect, useState, setState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "./Survey.css";
import "../App.css";
import { auth, db } from "../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import TeamSurveyQuestionRow from "./TeamSurveyQuestionRow";
import DatePicker from "./DatePicker";


function TeamSurveyResults() {
    const [user, loading] = useAuthState(auth);
    const [selectedSegment, setSelectedSegment] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [currentSurveys, setCurrentSurveys] = useState([]);

    const [question1Average, setQuestion1Value] = useState(0);
    const [question2Average, setQuestion2Value] = useState(0);
    const [question3Average, setQuestion3Value] = useState(0);
    const [question4Average, setQuestion4Value] = useState(0);
    const [question5Average, setQuestion5Value] = useState(0);
    const [question6Average, setQuestion6Value] = useState(0);
    const [question7Average, setQuestion7Value] = useState(0);
    const [question8Average, setQuestion8Value] = useState(0);
    const [question9Average, setQuestion9Value] = useState(0);
    const [question10Average, setQuestion10Value] = useState(0);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const teamCode = urlSearchParams.get("teamCode");
    const teamName = urlSearchParams.get("teamName");
    const navigate = useNavigate();
    const handleStartSurvey = () => navigate("/survey");

    function teamSurveyLink(code, name) {
        const paramName = name.replace(/\s+/g, '-');
        return `https://app.protalkgame.com/survey?teamCode=${code}&teamName=${paramName}`;
    };

    function copyTeamSurveyLink(code, name) {
        // Copy the text inside the text field
        navigator.clipboard.writeText(teamSurveyLink(code, name));
        // Alert the copied text
        alert("Copied survey link!");
    };

    function checkDateFor7Days(surveyItem) {
        console.log('CHECKING FOR 7 DAYS');
        const surveyDate = new Date(surveyItem.completionDate);
        var diffDays = new Date().getDate() - surveyDate.getDate();
        var diffMonths = new Date().getMonth() - surveyDate.getMonth();
        var diffYears = new Date().getFullYear() - surveyDate.getFullYear();
        console.log(`SURVEY DATE: ${surveyDate}, diffDays: ${diffDays}, diffMonths: ${diffMonths}, diffYears: ${diffYears}`);
        return diffDays <= 7 && diffMonths == 0 && diffYears == 0;
    }

    function checkDateFor30Days(surveyItem) {
        console.log('CHECKING FOR 30 DAYS');
        const surveyDate = new Date(surveyItem.completionDate);
        var diffDays = new Date().getDate() - surveyDate.getDate();
        var diffMonths = new Date().getMonth() - surveyDate.getMonth();
        var diffYears = new Date().getFullYear() - surveyDate.getFullYear();
        console.log(`SURVEY DATE: ${surveyDate}, diffDays: ${diffDays}, diffMonths: ${diffMonths}, diffYears: ${diffYears}`);
        console.log(diffDays <= 31 && diffMonths == 0 && diffYears == 0);
        return diffDays <= 31 && diffMonths == 0 && diffYears == 0
    }

    function setScoreAverages(surveys) {
        var filteredSurveys = surveys
        if (selectedSegment == 0) {
            filteredSurveys = surveys.filter((element) => { 
                return checkDateFor7Days(element.data());
            })
        } else if (selectedSegment == 1) {
            filteredSurveys = surveys.filter((element) => { 
                return checkDateFor30Days(element.data());
            })
        } else {
            console.log('NO FILTER')
        }
        console.log(`FILTERED RESULTS: ${filteredSurveys.length}`)
        
        var tempAverage1 = 0;
        var tempAverage2 = 0;
        var tempAverage3 = 0;
        var tempAverage4 = 0;
        var tempAverage5 = 0;
        var tempAverage6 = 0;
        var tempAverage7 = 0;
        var tempAverage8 = 0;
        var tempAverage9 = 0;
        var tempAverage10 = 0;

        filteredSurveys.forEach((survey) => {
            if (tempAverage1 === 0) {
                tempAverage1 = survey.data().question1Value;
            } else {
                tempAverage1 = (tempAverage1 + survey.data().question1Value) / 2
            }
            if (tempAverage2 === 0) {
                tempAverage2 = survey.data().question2Value;
            } else {
                tempAverage2 = (tempAverage2 + survey.data().question2Value) / 2
            }
            if (tempAverage3 === 0) {
                tempAverage3 = survey.data().question3Value;
            } else {
                tempAverage3 = (tempAverage3 + survey.data().question3Value) / 2
            }
            if (tempAverage4 === 0) {
                tempAverage4 = survey.data().question4Value;
            } else {
                tempAverage4 = (tempAverage4 + survey.data().question4Value) / 2
            }
            if (tempAverage5 === 0) {
                tempAverage5 = survey.data().question5Value;
            } else {
                tempAverage5 = (tempAverage5 + survey.data().question5Value) / 2
            }
            if (tempAverage6 === 0) {
                tempAverage6 = survey.data().question6Value;
            } else {
                tempAverage6 = (tempAverage6 + survey.data().question6Value) / 2
            }
            if (tempAverage7 === 0) {
                tempAverage7 = survey.data().question7Value;
            } else {
                tempAverage7 = (tempAverage7 + survey.data().question7Value) / 2
            }
            if (tempAverage8 === 0) {
                tempAverage8 = survey.data().question8Value;
            } else {
                tempAverage8 = (tempAverage8 + survey.data().question8Value) / 2
            }
            if (tempAverage9 === 0) {
                tempAverage9 = survey.data().question9Value;
            } else {
                tempAverage9 = (tempAverage9 + survey.data().question9Value) / 2
            }
            if (tempAverage10 === 0) {
                tempAverage10 = survey.data().question10Value;
            } else {
                tempAverage10 = (tempAverage10 + survey.data().question10Value) / 2
            }
        });

        setQuestion1Value(tempAverage1);
        setQuestion2Value(tempAverage2);
        setQuestion3Value(tempAverage3);
        setQuestion4Value(tempAverage4);
        setQuestion5Value(tempAverage5);
        setQuestion6Value(tempAverage6);
        setQuestion7Value(tempAverage7);
        setQuestion8Value(tempAverage8);
        setQuestion9Value(tempAverage9);
        setQuestion10Value(tempAverage10);
        setCurrentSurveys(filteredSurveys);
    };
    

    function inSameDay(d1, d2) {
        return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate();
    };

    function dateChanged(date) {
        console.log("Date Changed");
        setStartDate(date);
        fetchPastSurveys();
    };

    function selectedLast7Days() {
        setSelectedSegment(0);
    };

    function selectedLast30Days() {
        setSelectedSegment(1);
    };

    function selectedAllTime() {
        setSelectedSegment(2);
    };

    const fetchPastSurveys = async () => {
        console.log(`SELECTED SEGMENT:: ${selectedSegment}`)
        if (user != null) {
            try {
                console.log("TEAM CODE: " + teamCode);
                const q = query(collection(db, "surveys"), where("teamCode", "==", teamCode));
                const doc = await getDocs(q);
                console.log(doc);
                const mySurveys = doc.docs;
                console.log(mySurveys);
                if (mySurveys != null) {
                    const sortedSurveys = mySurveys.sort((a, b) => new Date(b.data().completionDate) - new Date(a.data().completionDate))
                    setScoreAverages(sortedSurveys);
                } else {
                    console.log("Missing Survey Data");
                    return;
                }
            } catch (err) {
                console.log("An error occured while fetching survey data");
                console.error(err);
                
            }
        }
    };

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/my-surveys");
        // getScoreMeaning(score);
        fetchPastSurveys();
    }, [user, loading, selectedSegment]);
    return (
        <div className="page-container">
            <h1 className="survey-title" style={{color: 'white'}}>GROUP RESULTS</h1>
            <div className="segment-div">
                <a className={`segment-item ${selectedSegment == 0 ? "segment-selected": "segment-unselected"}`} style={{marginRight: '8px'}} onClick={() => {selectedLast7Days()}}>LAST 7 DAYS</a>
                <a className={`segment-item ${selectedSegment == 1 ? "segment-selected": "segment-unselected"}`} style={{marginLeft: '8px'}} onClick={()=> {selectedLast30Days()}}>LAST 30 DAYS</a>
                <a className={`segment-item ${selectedSegment == 2 ? "segment-selected": "segment-unselected"}`} style={{marginLeft: '8px'}} onClick={()=> {selectedAllTime()}}>ALL TIME</a>
            </div>
            {(currentSurveys.length === 0)? (
                <div>
                    <p style={{color: "#dddddd", textAlign: "center"}}>No survey results</p>
                </div>
            ) : (
                <div>
                <TeamSurveyQuestionRow
                    questionNumber="1"
                    score={question1Average}
                />
                <TeamSurveyQuestionRow
                    questionNumber="2"
                    score={question2Average}
                />
                <TeamSurveyQuestionRow
                    questionNumber="3"
                    score={question3Average}
                />
                <TeamSurveyQuestionRow
                    questionNumber="4"
                    score={question4Average}
                />
                <TeamSurveyQuestionRow
                    questionNumber="5"
                    score={question5Average}
                />
                <TeamSurveyQuestionRow
                    questionNumber="6"
                    score={question6Average}
                />
                <TeamSurveyQuestionRow
                    questionNumber="7"
                    score={question7Average}
                />
                <TeamSurveyQuestionRow
                    questionNumber="8"
                    score={question8Average}
                />
                <TeamSurveyQuestionRow
                    questionNumber="9"
                    score={question9Average}
                />
                <TeamSurveyQuestionRow
                    questionNumber="10"
                    score={question10Average}
                    suggestionText=""
                />
                <p style={{color: 'white'}}><b>What do my results mean?</b></p>
                <p style={{color: '#dddddd'}}>Your results can help you identify what questions in particular your team might be struggling with. As you play Pro Talk with your team pay close attention to the challenges outlined above, they may help you start the right conversations to help your team break through those bad communication habits.</p>
            </div>
            )}
            <div style={{marginTop: '32px', marginBottom: '48px'}}>
                <a className="final-submit-btn blue-btn-dash" href={"https://www.protalkgame.com"} target="_blank">Buy Pro Talk</a>
                <a className="final-submit-btn green-btn-dash" style={{marginTop: '16px'}} onClick={() => copyTeamSurveyLink(teamCode, teamName)}>Copy Team Survey Link</a>
                <a className="final-submit-btn red-btn-dash" style={{marginTop: '16px'}} onClick={handleStartSurvey}>Start New Quick Survey</a>
            </div>
        </div>
    );
}

export default TeamSurveyResults;