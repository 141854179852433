import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "../survey/Survey.css";
import "../App.css";
import { auth, db } from "../firebase";
import { query, collection, getDocs, where, addDoc, deleteDoc, doc } from "firebase/firestore";
import moment from 'moment';
import QRCode from "react-qr-code";
import Overlay from "../resources/Overlay";
import { FaTrash } from "react-icons/fa";

function MyTeam() {
  const [user, loading, error] = useAuthState(auth);
  const [teamGroups, setTeamGroups] = useState([]);
  const [hasGold, setGold] = useState(false);
  const [newTeamName, setNewTeamName] = useState("");
  const navigate = useNavigate();
  const handleStartSurvey = () => navigate("/survey");
  const viewTeamGroupResults = (code, power) => navigate(`/team-survey-result?teamCode=${code}$superpower=${power}`);
  const [isOverlayOpen, setOverlayOpen] = useState(false);

  // Colors
  const red = "#C65447";
  const redBackground = "#F4DDDA";
  const green = "#509E6F";
  const greenBackground = "#DCECE2";
  const blue = "#1961A8";
  const blueBackground = "#D1DFEE";
  const yellow = "#FFC337";
  const orange = "#ED6742";
  const gold = "#F0B52C";
  const goldBackground = "#FCF0D5";

  const fetchGoldStatus = async () => {
    try {
      console.log(user?.uid)
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      console.log(data)
      setGold(data.hasGold);
    } catch (err) {
      console.log("FAILED TO GET GOLD STATUS")
      console.error(err);
      // alert("An error occured while fetching user data");
    }
};

const fetchTeamGroups = async () => {
    if (user != null) {
        try {
            const q = query(collection(db, "team-survey"), where("userId", "==", user?.uid));
            const doc = await getDocs(q);
            console.log(doc);
            const teamGroups = doc.docs;
            console.log(teamGroups);
            if (teamGroups != null) {
                const sortedGroups = teamGroups.sort((a, b) => new Date(b.data().createdAt) - new Date(a.data().createdAt))
                setTeamGroups(sortedGroups);
            } else {
                console.log("Missing Team Data");
                return;
            }
        } catch (err) {
            console.log("An error occured while fetching survey data");
            console.error(err);
            
        }
    }
};

const openOverlay = () => {
    setOverlayOpen(true);
};

const closeOverlay = () => {
    setOverlayOpen(false);
};

const createTeamSurveyGroup = async () => {
    if (!hasGold && teamGroups.length >= 1) {
        setOverlayOpen(true);
        return;
    }

    if (newTeamName == "") {
        alert("Please enter a group name to continue");
        return;
    }

    const collectionRef = collection(db, 'team-survey');
    let currentDate = new Date();
    let formattedDate = currentDate.toLocaleString();

    let codeToUse = generatedCode();
    console.log(codeToUse);
    // Make sure no other team code of this same matching exist
    const q = query(collection(db, "team-survey"), where("teamCode", "==", codeToUse));
    const doc = await getDocs(q);
    console.log(doc.docs);
    if (doc.docs != null && doc.docs.length === 0) {
        const newTeamData = {
        userId: user?.uid ?? "",
        teamCode: `${codeToUse}`,
        createdAt: formattedDate,
        name: newTeamName
        };
        addDoc(collectionRef, newTeamData).then(() => {
        console.log('Document added successfully!');
        fetchTeamGroups();
        }).catch((error) => {
        console.log('Error adding document:', error);
        });
    } else {
        console.log("Trying again since the code was bad");
        await createTeamSurveyGroup();
    }
};

  function generatedCode() {
    let letter1 = generateRandomLetter();
    let letter2 = generateRandomLetter();
    let number1 = generateRandomNumber();
    let number2 = generateRandomNumber();
    return `${letter1}${number1}${letter2}${number2}`;
  };

  function generateRandomNumber() {
    return Math.floor(Math.random()  
    * (9 - 0 + 1)) + 0; 
  };

  function generateRandomLetter() {
    const alphabet = "ABCDEFGHJKLMNPQRSTUVWXYZ";
    return (alphabet[Math.floor(Math.random() * alphabet.length)]);
  };

  function teamSurveyLink(code, name) {
    const paramName = name.replace(/\s+/g, '-');
    return `https://getprotalk.com/survey?teamCode=${code}&teamName=${paramName}`;
  };

  function teamResultLink(code, name) {
    const paramName = name.replace(/\s+/g, '-');
    return `/team-survey-result?teamCode=${code}&teamName=${paramName}`;
  };

  function copyTeamSurveyLink(code, name) {
      // Copy the text inside the text field
      navigator.clipboard.writeText(teamSurveyLink(code, name));
      var textField = document.createElement('textarea')
      textField.innerText = teamSurveyLink(code, name);
      document.body.appendChild(textField)
      textField.select()
      document.execCommand('copy')
      textField.remove()
      // Alert the copied text
      alert("Copied survey link!");
  };

  async function deleteGroup(code) {
    // Alert the copied text
    console.log(`CODE ${code}`);

    const q = query(collection(db, "team-survey"), where("teamCode", "==", code));
    const docs = await getDocs(q);
    console.log(`DOCS: ${docs.docs[0].id}`);
    const docRef = doc(db, "team-survey", docs.docs[0].id);

    deleteDoc(docRef);
    fetchTeamGroups();
    alert("Group deleted!");
};

  useEffect(() => {
    if (loading) return;
    // if (!user) return navigate("/");
    fetchGoldStatus();
    fetchTeamGroups();
  }, [user, loading]);
  return (
    <div className="page-container">
        <div style={{marginTop: '32px', display: 'flex', alignItems: 'center'}}>
        <div style={{marginRight: '16px'}}>
            <h4 className="dashboard-tag-left">COMING SOON</h4>
            <h1 className="dashboard-section-title" style={{textAlign: 'left'}}>Discover new trends, directly from your employees</h1>
            <p className="dashboard-section-subtitle" style={{textAlign: 'left'}}>Pro Talk currently offers 'Survey Groups' which allow you to gather results from each survey that your team makes. You can use this data to set up trainings and goals with your employees. Coming soon is a brand new way to gain insights from your employees with a simple SMS message.</p>
        </div>
        <div style={{display: 'grid'}}>
            <img style={{objectFit: "contain", width: '225px'}} src={require(`../assets/images/38.png`)} />
            <a className="final-submit-btn blue-btn-dash" style={{margin: '48px auto auto auto', width: '100px'}} href="https://protalkgame.com/pages/contact" target="blank">Sign Up</a>
        </div>
      </div>
      <h1 className="survey-title" style={{marginTop: '24px', color: 'white'}}>MY SURVEY GROUPS</h1>
      <div>
        <div className="dashboard-score-container" style={{background: '#00000024'}}>
            {(user != null)?
                (
                <div style={{width: '100%'}}>
                {teamGroups.map(group => (
                  // <div style={{marginBottom: '16px'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', height: '120px', padding: '16px 0'}}>
                      <div style={{display: 'flow', margin: 'auto 0px'}}>
                        <h4 className="score-text" style={{textAlign: 'start', margin: 'auto 0px', verticalAlign: 'center', paddingTop: '12px', paddingBottom: '4px', fontSize: '12px'}}>Group Name</h4>
                        <h4 style={{textAlign: 'start', margin: 'auto 0px', fontWeight: '600', fontSize: '18px', color: 'white'}}>{group.data().name}</h4>
                        <h4 className="score-text" style={{textAlign: 'start', margin: 'auto 0px', verticalAlign: 'center', paddingTop: '12px', paddingBottom: '4px', fontSize: '12px'}}>Shareable Code</h4>
                        <h4 className="score-text" style={{textAlign: 'start', margin: 'auto 0px', fontWeight: '600', color: 'white', fontSize: '24px'}}>{group.data().teamCode}</h4>
                      </div>
                        <a className="inline-btn inline-blue-btn-dash" href={`${teamResultLink(group.data().teamCode, group.data().name)}`}>View Report</a>
                        <a className="inline-btn inline-blue-btn-dash" onClick={() => copyTeamSurveyLink(group.data().teamCode, group.data().name)}>Copy Survey Link</a>
                        <a className="inline-btn red-btn-dash" style={{padding: '8px', borderRadius: '12px'}} onClick={() => { if (window.confirm('Are you sure you would like to delete your group?')) deleteGroup(group.data().teamCode)} }><FaTrash/></a>
                        {/* <QRCode
                          style={{margin: 'auto 0px'}}
                          title="Pro Talk Survey"
                          value={teamSurveyLink(group.data().teamCode, group.data().name)}
                          bgColor="#FFFFFF"
                          fgcolor="#000000"
                          size={80}
                        /> */}
                    </div>
                    // </div>
                ))}
                  <div style={{marginTop: '30px', marginBottom: '40px', display: 'flex'}}>
                    <input name="newTeamName" placeholder="Enter Group Name" style={{background: '#f4f4f4', borderRadius: '10px', borderColor: '#000000', padding: '10px', marginRight: '20px', fontSize: 'large'}} onChange={(e) => { 
                          setNewTeamName(e.target.value); 
                      }}></input>
                    <a className="final-submit-btn green-btn-dash" onClick={createTeamSurveyGroup}>Create New Group</a>
                  </div>
                </div>
                ) : (
                    <a className="inline-btn inline-blue-btn-dash" href="/account">Log in to create a survey group</a>
                )}
            </div>
        </div>
      {isOverlayOpen && (
            <Overlay onClose={closeOverlay}>
              <div className="scrollable-content">
                {/* Your scrollable content goes here */}
                <h1 className="dashboard-title" style={{textAlign: 'center', fontSize: '54px', marginBottom: '2px'}}><span style={{color: blue}}>P</span><span style={{color: red}}>R</span><span style={{color: yellow}}>O</span> <span style={{color: green}}>T</span><span style={{color: orange}}>A</span><span style={{color: blue}}>L</span><span style={{color: red}}>K</span></h1>
                <h2 style={{textAlign: 'center', color: gold, background: goldBackground, padding: '4px', margin: '0 auto', width: '100px', borderRadius: '10px'}}>GOLD</h2>
                <p>Unlimited access to Pro Talk content including cards and book. Gain access to all Pro Talk team survey results and insights. Create an unlimited number of survey groups and view a full history of any survey you have taken before. Also gain access to all future updates and content including Pro Talk Kids™, Pro Talk Sales™ and more!</p>
                <a className="final-submit-btn green-btn" href="https://protalkgame.com/products/pro-talk-gold" target="blank">Upgrade Now</a>
              </div>
            </Overlay>
          )}
    </div>
  );
}

export default MyTeam;