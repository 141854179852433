import React, { useRef, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "./Resources.css";
import "../App.css";
import { auth, db } from "../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import 'font-awesome/css/font-awesome.min.css';
import { useSprings, animated, to } from 'react-spring';
import ProTalkCard from './ProTalkCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Overlay from "./Overlay";

function Resources() {
    const [value, setValue] = React.useState('Accept')
    const [gone] = useState(() => new Set());

    const urlSearchParams = new URLSearchParams(window.location.search);
    const _section = urlSearchParams.get("section") ?? "challenges";

    const [section, setSection] = useState(_section);
    const [user, loading] = useAuthState(auth);
    const [accountType, setAccountType] = useState("");
    const navigate = useNavigate();
    const [cards, setCards] = useState([]);
    const [solutionCards, setSolutionCards] = useState([]);
    const [challengeCards, setChallengeCards] = useState([]);
    const [selectedCard, setSelectedCard] = useState();
    const [isOverlayOpen, setOverlayOpen] = useState(false);
    const [isCardSelected, setCardSelected] = useState(false);

    // Colors
    const red = "#C65447";
    const redBackground = "#F4DDDA";
    const green = "#509E6F";
    const greenBackground = "#DCECE2";
    const blue = "#1961A8";
    const blueBackground = "#D1DFEE";
    const yellow = "#FFC337";
    const orange = "#ED6742";
    const gold = "#F0B52C";
    const goldBackground = "#FCF0D5";

    const fetchAccountType = async () => {
      try {
        if (user == null) {
          setAccountType("basic");
          console.log("No user data");
          return;
        }
        const querySnapshot = await getDocs(collection(db, "customers", user.uid, "subscriptions"));
        var hasSubscription = false;
        var productId = "";
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          const status = doc.data().status;
          if (!hasSubscription) {
            if (status == "trialing" || status == "active") {
              hasSubscription = true;
              productId = doc.data().product.id;
            }
          }
        });
        if (hasSubscription) {
          if (productId == "prod_PbBu30DBX1c1j0") {
            setAccountType("premium");
          } else if (productId == "prod_POZmffO88V5Kwe") {
            setAccountType("plus");
          }
        } else {
          setAccountType("basic");
        }
      } catch (err) {
        console.error(err);
        console.log("An error occured while fetching user data");
      }
    };

    const fetchSolutions = async () => {
      if (user != null) {
          try {
              console.log("solutions");
              const q = query(collection(db, "solutions"));
              const doc = await getDocs(q);
              const myCards = doc.docs;
              console.log(myCards);
              if (myCards != null) {
                  // if (!hasGold) {
                  //   myCards = myCards.slice(0, 6)
                  // }
                  const sortedCards = myCards.sort((a, b) => a.data().id < b.data().id ? -1 : 1)
                  setSolutionCards(sortedCards);
              } else {
                  console.log("Missing Card Data");
                  return;
              }
            } catch (err) {
              console.log("An error occured while fetching card data");
              console.error(err);
              
            }
      }
    };

    const fetchChallenges = async () => {
      if (user != null) {
          try {
              const q = query(collection(db, "challenges"));
              const doc = await getDocs(q);
              const myCards = doc.docs;
              if (myCards != null) {
                const sortedCards = myCards.sort((a, b) => a.data().id < b.data().id ? -1 : 1)
                  setChallengeCards(sortedCards);
                  if (cards.length == 0) {
                    setCards(sortedCards);
                  }
              } else {
                  console.log("Missing Card Data");
                  return;
              }
            } catch (err) {
              console.log("An error occured while fetching card data");
              console.error(err);
              
            }
      }
    };

    function getCardType(card) {
      if (card?.id < 37) {
        return "CHALLENGE";
      } else {
        return "SOLUTION";
      }
    };

    function getCardBlur(card) {
      if (!(accountType === 'plus' || accountType === 'premium')) {
        if ((card.id >= 7 && card.id <= 36) || (card.id > 42)) {
          return 10;
        }
      } else {
        return 0;
      }
    };

    function getCardColor(card) {
      if (card.id < 37) {
        return red;
      } else {
        return green;
      }
    }; 

    function selectedChallenges() {
      setSection("challenges");
      let stateObj = { id: "1" }; 
      window.history.pushState(stateObj, "Challenges", "/resources?section=challenges");
      setCards(challengeCards);         
    };
  
    function selectedSolutions() {
      setSection("solutions");
      let stateObj = { id: "2" }; 
      window.history.pushState(stateObj, "Solutions", "/resources?section=solutions");
      setCards(solutionCards);
    }; 

    function selectedBook() {
      let stateObj = { id: "3" }; 
      window.history.pushState(stateObj, "Book", "/resources?section=book");
      setSection("book");
    }; 

    function selectNewCard(card) {
      if (!(accountType === 'plus' || accountType === 'premium')) {
        if ((card.id >= 7 && card.id <= 36) || (card.id > 42)) {
          openOverlay();
        } else {
          setSelectedCard(card);
          setCardSelected(true);
        }
      } else {
        setSelectedCard(card);
        setCardSelected(true);
      }
    };

    const openOverlay = () => {
      setOverlayOpen(true);
    };
  
    const closeOverlay = () => {
      setOverlayOpen(false);
    };

    const closeCardOverlay = () => {
      setSelectedCard(null);
      setCardSelected(false);
    };

    useEffect(() => {
        if (loading) return;
        // if (!user) return navigate("/");
        fetchAccountType();
        fetchChallenges();
        fetchSolutions();
    
        if (section === "solutions") {
          console.log("SHOW SOLUTIONS");
         
        } else {
          console.log("SHOW CHALLENGES");
          
        }
        
    }, [user, loading]);

    return(
        <div className="page-container" style={{width: '90%', maxWidth: '1200px'}}>
          <div style={{marginTop: '32px', display: 'flex', alignItems: 'center'}}>
            <div style={{marginRight: '16px'}}>
              <h4 className="dashboard-tag-left">GAME CHANGING TOOLS</h4>
              <h1 className="dashboard-section-title" style={{textAlign: 'left'}}>A fun card game, and powerful book</h1>
              <p className="dashboard-section-subtitle" style={{textAlign: 'left'}}>The Pro Talk Toolkit will allow you to play Pro Talk with your team in person. A 10 minute game with your team will get conversations going. Use the Pro Talk Book to dive deeper into topics your team needs help with.</p>
            </div>
            <div style={{display: 'grid'}}>
              <img style={{objectFit: "contain", width: '100%'}} src={require(`../assets/images/bundle-2.png`)} />
              <a className="final-submit-btn green-btn-dash" style={{margin: '48px auto auto auto', width: '80%'}} href="https://buy.stripe.com/8wM3fB7v64Or6ek3ce" target="blank">Buy Toolkit</a>
            </div>
          </div>
          <div>
            <h1 className="dashboard-section-title survey-title" style={{fontSize: '32px', textAlign: 'left', margin: '24px auto auto auto'}}>Digital Resources</h1>
            {(user == null)? (
              <div className="my-survey-container login-container" style={{marginTop: '24px', paddingTop: '12px', paddingBottom: '12px', background: '#00000024'}}> 
                <a className="inline-btn inline-blue-btn-dash" href="/account" style={{textAlign: 'center', width: '100%'}}>Log in for Digital Access</a>
              </div>
            ) : (
              <div>
                <div className="segment-div" style={{width: '80%'}}>
                  <a className={`segment-item ${section === "challenges" ? "segment-selected": "segment-unselected"}`} onClick={() => {selectedChallenges()}}>CHALLENGE CARDS</a>
                  <a className={`segment-item ${section === "solutions" ? "segment-selected": "segment-unselected"}`} style={{marginLeft: '8px'}} onClick={()=> {selectedSolutions()}}>SOLUTION CARDS</a>
                  <a className={`segment-item ${section === "book" ? "segment-selected": "segment-unselected"}`} style={{marginLeft: '8px'}} onClick={()=> {selectedBook()}}>BOOK</a>
                </div>
                {(section === "challenges" || section === "solutions")? (
                  <div className="card-collection">
                  {cards.map(card => (
                    <ProTalkCard 
                      key={card.data().id}
                      type={getCardType(card.data())}
                      card={card.data()}
                      color={getCardColor(card.data())}
                      blur={getCardBlur(card.data())}
                      onClick={() => {
                        selectNewCard(card.data());
                      }} />
                  ))}
                  </div>
                ) : (
                  <div className="page-container">
                    {(accountType === 'plus' || accountType === 'premium')? (
                      <a className="final-submit-btn gray-btn" href="https://firebasestorage.googleapis.com/v0/b/pro-talk-game.appspot.com/o/HowToPlayPDF.pdf?alt=media&token=d4aba260-22e1-4acf-a5c4-d9fad7766c22" target="blank" >View full Pro Talk Book</a>
                    ) : (
                      <div>
                      <a className="final-submit-btn gray-btn" style={{marginTop: '24px'}} href="https://firebasestorage.googleapis.com/v0/b/pro-talk-game.appspot.com/o/HowToPlayPDF.pdf?alt=media&token=d4aba260-22e1-4acf-a5c4-d9fad7766c22" target="blank" >View Pro Talk Sample Book</a>
                      <a className="final-submit-btn gold-btn" style={{marginTop: '24px'}} href="https://buy.stripe.com/28o8zVcPq5Sv1Y4001">Upgrade for Full Access</a>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
          {isOverlayOpen && (
            <Overlay onClose={closeOverlay}>
              <div className="scrollable-content">
                {/* Your scrollable content goes here */}
                <h1 className="dashboard-title" style={{textAlign: 'center', fontSize: '54px', marginBottom: '2px'}}><span style={{color: blue}}>P</span><span style={{color: red}}>R</span><span style={{color: yellow}}>O</span> <span style={{color: green}}>T</span><span style={{color: orange}}>A</span><span style={{color: blue}}>L</span><span style={{color: red}}>K</span></h1>
                <h2 style={{textAlign: 'center', color: gold, background: goldBackground, padding: '4px', margin: '0 auto', width: '100px', borderRadius: '10px'}}>PLUS</h2>
                <p>Unlimited access to Pro Talk content including cards and book. Gain access to all Pro Talk team survey results and insights. Create an unlimited number of survey groups and view a full history of any survey you have taken before. Play Pro Talk virtually with your team!</p>
                <a className="final-submit-btn green-btn" href="/pricing">Upgrade Now</a>
              </div>
            </Overlay>
          )}
          {isCardSelected && (
            <Overlay onClose={closeCardOverlay}>
              <div className="scrollable-content">
                {/* Your scrollable content goes here */}
                <div style={{background: 'white', borderRadius: '10px', margin: '0 auto', maxHeight: '80%', overflowY: 'auto'}}>
                  <p className="card-title" style={{fontSize: '20px', color: `${getCardType(selectedCard) === "CHALLENGE" ? red : green}`}}>{getCardType(selectedCard)}</p>
                  <p className="card-title" style={{fontSize: '32px', fontWeight: '600', marginTop: '12px', textTransform: 'uppercase'}}>{selectedCard.name}</p>
                  <img id="cardimg" style={{objectFit: "contain", width: "100%", height: '180px', marginTop: '12px'}} src={require(`../assets/images/${selectedCard.id}.png`)} />
                  <p style={{fontSize: "16px", textAlign: "center", height: "auto", marginTop: "12px", color: "gray"}}>{selectedCard.detail}</p>
                  <div style={{padding: '4px 16px', background: `${getCardType(selectedCard) === "CHALLENGE" ? redBackground : greenBackground}`, borderRadius: '8px'}}>
                    <p style={{fontWeight: '600', color: `${getCardType(selectedCard) === "CHALLENGE" ? red : green}`}}>{getCardType(selectedCard) === "CHALLENGE" ? 'Why It\'s Bad' : 'Why It\'s Good'}</p>
                    {(getCardType(selectedCard) == "CHALLENGE")? (
                      <div>
                        {selectedCard.whyBadPoints.map(point => (
                        <p style={{fontSize: '14px'}}>• {point}</p>
                          ))}
                      </div>
                    ) : (
                      <div>
                        {selectedCard.whyGoodPoints.map(point => (
                        <p style={{fontSize: '14px'}}>• {point}</p>
                          ))}
                      </div>  
                    )}
                  </div>
                  {(accountType === 'plus' || accountType === 'premium')? (
                    <div style={{padding: '4px 16px', background: `${blueBackground}`, borderRadius: '8px', marginTop: '16px', marginBottom: '16px'}}>
                      <p style={{fontWeight: '600', color: `${blue}`}}>Let's Talk About It</p>
                      {selectedCard.talkingPoints.map(point => (
                      <p style={{fontSize: '14px'}}>• {point}</p>
                    ))}
                    </div>
                  ) : (
                    <div style={{padding: '4px 16px', background: `${blueBackground}`, borderRadius: '8px', marginTop: '16px', marginBottom: '16px'}}>
                      <p style={{fontWeight: '600', color: `${blue}`}}>Let's Talk About It</p>
                      <a className="final-submit-btn green-btn" style={{marginBottom: '12px', minWidth: '100px', width: '30%'}} onClick={ () => {
                        openOverlay();
                      }}>Upgrade to see more</a>
                      <p></p>
                    </div>
                  )}
                  <div style={{padding: '4px 16px', background: '#f4f4f4', borderRadius: '8px', marginTop: '16px', marginBottom: '16px'}}>
                      <p style={{fontWeight: '600', color: '#4D4D4D'}}>{getCardType(selectedCard) === "CHALLENGE" ? 'Related Solution Cards' : 'Related Challenge Cards'}</p>
                      {selectedCard.availableMatches.map(point => (
                      <p style={{fontSize: '14px'}}>{cards.filter( card => card.id == point)[0]?.data().name ?? `Page Number ${point}`}</p>
                    ))}
                  </div>
                </div>
              </div>
            </Overlay>
          )}
        </div>
        
    );
}
export default Resources;