import React from 'react';
import { FaChevronUp } from 'react-icons/fa';
import RoundedRectangle from './RoundedRectangle';

class TeamSurveyQuestionRow extends React.Component {  
    render() {
      const { questionNumber, score, suggestionText } = this.props;

      const surveyQuestions = [
        "My co-workers listen to my questions and ideas.",
        "When I don't understand something at work, I feel comfortable asking for clarification.",
        "In work related matters, I feel safe to speak my mind.",
        "My co-workers are kind and respectful.",
        "My co-workers care about how I feel.",
        "People I speak with give me their full attention.",
        "Our work climate encourages clarifying questions.",
        "Our work climate encourages ideas that go against the status quo.",
        "The people I work with speak respectfully to each other.",
        "We have an emotionally supportive work climate."
    ]

      // Colors
      const red = "#C65447";
      const redBackground = "#C654474d";
      const blue = "#268fff";
      const blueBackground = "#268fff4d";
      const green = "#509E6F";
      const greenBackground = "#509E6F4d";
      const orange = "#ED6742";
      const orangeBackground = "#ED67424d";

      function getColor() {
        if (score <= 2.9) {
          return red;
        } else if (score > 2.9 && score <= 4.9) {
          return orange;
        } else if (score > 4.9 && score <= 7.5) {
          return blue;
        } else {
          return green;
        }
      };

      function getBackgroundColor() {
        if (score <= 2.9) {
          return redBackground;
        } else if (score > 2.9 && score <= 4.9) {
          return orangeBackground;
        } else if (score > 4.9 && score <= 7.5) {
          return blueBackground;
        } else {
          return greenBackground;
        }
      };

      return (
        <div>
        <h4 style={{display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'flex-start', alignItems: 'center', marginTop: '16px', color: 'white'}}>Question {questionNumber} Average Response</h4>
        <p style={{color: '#dddddd'}}>{surveyQuestions[questionNumber - 1]}</p>
        <div>
            <RoundedRectangle
                circleHorizontalPosition={score}
                borderColor={getColor()}
                circleColor={getColor()}
                triangleColor={getColor()}
                backgroundColor={getBackgroundColor()}
            />
            {/* <div>
                <FaChevronDown />
            </div> */}
        </div>
        <p style={{marginTop: "64px", color: "#dddddd"}}>{suggestionText || ""}</p>
      </div>
      );
    }
  }
  
  export default TeamSurveyQuestionRow;