import React from 'react';

class PricingPage extends React.Component {
    render() {
        const { email } = this.props;

        return (
            <stripe-pricing-table 
                pricing-table-id="prctbl_1OmecHDmRtME6qTqGQyeUOgF"
                publishable-key="pk_live_51OZmNwDmRtME6qTqMetVAuPuyiJh30Ms1wKJAsfbcdZ0LKeaYpNNj1QZEOyFa48TYvZBJu65VDhRge15nlVTXa9g00JsdvwLfT"
                customer-email={{email}}>
            </stripe-pricing-table>
        );
    }
}

export default PricingPage;