import proTalkHeader from './proTalkHeader.svg';
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./login/Login";
import Dashboard from "./dashboard/Dashboard";
import Account from "./account/Account";
import Navbar from './components/Navbar';
import Resources from './resources/Resources';
import TeamSurvey from './survey/TeamSurvey';
import MySurveys from './survey/MySurveys';
import MyTeam from './team/MyTeam';
import SurveyResult from './survey/SurveyResult';
import TeamSurveyResults from './survey/TeamSurveyResults';
import Tutorial from './tutorial/Tutorial';
import Footer from './components/Footer/Footer';
import Upgrade from './Upgrade/Upgrade';
import Pricing from './pricing/Pricing'

function App() {
  return (
    <div className="app" style={{background: '#31425b'}}>
      <Router>
      <Navbar />
        <Routes>
          <Route exact path="/" element={<Dashboard />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/resources" element={<Resources />} />
          <Route exact path="/account" element={<Account />} />
          <Route exact path="/survey" element={<TeamSurvey />} />
          <Route exact path="/my-team" element={<MyTeam />} />
          <Route exact path="/my-surveys" element={<MySurveys />} />
          <Route exact path="/survey-result" element={<SurveyResult />} />
          <Route exact path="/team-survey-result" element={<TeamSurveyResults />} />
          <Route exact path="/get-started" element={<Tutorial />} />
          <Route exact path="/upgrade" element={<Upgrade />} />
          <Route exact path="/pricing" element={<Pricing />} />
        </Routes>
        
      </Router>
      <Footer/>
    </div>
  );
}
export default App;
