import React, { useRef, useEffect, useState } from "react";
import "./Resources.css";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useSprings, animated, to } from 'react-spring'
import LockOverlay from "./LockOverlay";

class ProTalkCard extends React.Component {  
    render() {
      const { card, type, color, blur, rotation, left, right, onClick } = this.props;

      return (
        <div className="card" style={{border: "10px", borderColor: `${color}`, borderStyle: "solid", filter: `blur(${blur}px)`, left: `${left}px`, right: `${right}px`, height: '300px', display: 'grid', rotate: `${rotation}deg`}} onClick={onClick}>
          <p className="card-title" style={{color: `${color}`}}>{type}</p>
          <p className="card-name">{blur === 10 ? '____' : card.name}</p>
          <img id="cardimg" style={{paddingLeft: "20px", paddingRight: "20px", objectFit: "contain", width: "80%", height: "120px"}} src={require(`../assets/images/${card.id}.png`)} />
          <p style={{fontSize: "14px", textAlign: "center", height: "auto", marginTop: "auto", paddingLeft: "4px", paddingRight: "4px", color: "gray"}}>{blur === 10 ? '____' : card.detail}</p>
        </div>
      );
    }
  }
  
  export default ProTalkCard;