import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "./Survey.css";
import "../App.css";
import { auth, db } from "../firebase";
import { query, collection, getDocs, where, addDoc, deleteDoc, doc } from "firebase/firestore";
import moment from 'moment';
import QRCode from "react-qr-code";
import Overlay from "../resources/Overlay";

function MySurveys() {
  const [user, loading, error] = useAuthState(auth);
  const [userSurveys, setUserSurveys] = useState([]);
  const [accountType, setAccountType] = useState("");
  const [latestSurveyScore, setLatestSurveyScore] = useState(0);
  const navigate = useNavigate();
  const handleStartSurvey = () => navigate("/survey");
  const [isOverlayOpen, setOverlayOpen] = useState(false);

  // Colors
  const red = "#C65447";
  const redBackground = "#F4DDDA";
  const green = "#509E6F";
  const greenBackground = "#DCECE2";
  const blue = "#1961A8";
  const blueBackground = "#D1DFEE";
  const yellow = "#FFC337";
  const orange = "#ED6742";
  const gold = "#F0B52C";
  const goldBackground = "#FCF0D5";

  const fetchAccountType = async () => {
    try {
      if (user == null) {
        setAccountType("basic");
        console.log("No user data");
        return;
      }
      const querySnapshot = await getDocs(collection(db, "customers", user.uid, "subscriptions"));
      var hasSubscription = false;
      var productId = "";
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        const status = doc.data().status;
        if (!hasSubscription) {
          if (status == "trialing" || status == "active") {
            hasSubscription = true;
            productId = doc.data().product.id;
          }
        }
      });
      if (hasSubscription) {
        if (productId == "prod_PbBu30DBX1c1j0") {
          setAccountType("premium");
        } else if (productId == "prod_POZmffO88V5Kwe") {
          setAccountType("plus");
        }
      } else {
        setAccountType("basic");
      }
    } catch (err) {
      console.error(err);
      console.log("An error occured while fetching user data");
    }
  };

  const fetchPastSurveys = async () => {
    if (user != null) {
        try {
            console.log("USER ID: " + user?.uid);
            const q = query(collection(db, "surveys"), where("userId", "==", user?.uid));
            const doc = await getDocs(q);
            console.log(doc);
            const mySurveys = doc.docs;
            console.log(mySurveys);
            if (mySurveys != null) {
                const sortedSurveys = mySurveys.sort((a, b) => new Date(b.data().completionDate) - new Date(a.data().completionDate))
                setUserSurveys(sortedSurveys);
                const recentSurvey = sortedSurveys[0].data()
                console.log(recentSurvey.score);
                setLatestSurveyScore(recentSurvey.score);
            } else {
                console.log("Missing Survey Data");
                return;
            }
          } catch (err) {
            console.log("An error occured while fetching survey data");
            console.error(err);
            
          }
    }
  };

  const openOverlay = () => {
    setOverlayOpen(true);
  };

  const closeOverlay = () => {
    setOverlayOpen(false);
  };

  useEffect(() => {
    if (loading) return;
    // if (!user) return navigate("/");
    fetchAccountType();
    fetchPastSurveys();
  }, [user, loading]);
  return (
    <div className="page-container">
      <div style={{marginTop: '32px', display: 'flex', alignItems: 'center'}}>
        <div style={{marginRight: '16px'}}>
          <h4 className="dashboard-tag-left">ADVANCED INSIGHTS</h4>
          <h1 className="dashboard-section-title" style={{textAlign: 'left'}}>Discover your communication strengths</h1>
          <p className="dashboard-section-subtitle" style={{textAlign: 'left'}}>Take the 10 question survey to gain insight into your team's current communication skills. As you take the survey you will be given information about what your team is doing well, and what your team can do to improve.</p>
        </div>
        <div style={{display: 'grid'}}>
          <img style={{objectFit: "contain", width: '80%', margin: 'auto'}} src={require(`../assets/images/53.png`)} />
          <a className="final-submit-btn blue-btn-dash" style={{margin: '48px auto auto auto', width: '80%'}} href="/survey">Take Survey</a>
        </div>
      </div>
      <h1 className="survey-title" style={{marginTop: '24px', color: 'white'}}>MY SURVEYS</h1>
      {/* <div>
        <a className="final-submit-btn blue-btn-dash" style={{marginBottom: '24px'}} onClick={handleStartSurvey}>Start Quick Survey</a>
      </div> */}
      <div>
            <div className="dashboard-score-container" style={{background: '#00000024'}}>
            {(user != null)?
                (
                <div style={{width: '100%'}}>
                {userSurveys.map(survey => (
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <div>
                        <h3 className="score-title" style={{marginBottom: '6px', color: '#FFFFFF'}}>Score <b style={{fontWeight: '800'}}>{survey.data().score}</b></h3>
                        <h4 className="score-text" style={{marginTop: '0px', fontSize: '14px', color: '#dddddd'}} >Completed on {moment(new Date(survey.data().completionDate)).format("MMMM D, YYYY")} at {moment(new Date(survey.data().completionDate)).format("h:mm a")}</h4>
                      </div>
                      <a className="inline-btn" style={{color: '#268fff'}} href={`/survey-result?score=${survey.data().score}&superpower=${survey.data().superpower ?? 37}`} target="_blank">View Report</a>
                    </div>
                ))}
                </div>
                ) : (
                    <a className="inline-btn inline-blue-btn-dash" href="/account" style={{textAlign: 'center', width: '100%'}}>Log in to see survey results</a>
                )}
            </div>
         </div>
      {isOverlayOpen && (
            <Overlay onClose={closeOverlay}>
              <div className="scrollable-content">
                {/* Your scrollable content goes here */}
                <h1 className="dashboard-title" style={{textAlign: 'center', fontSize: '54px', marginBottom: '2px'}}><span style={{color: blue}}>P</span><span style={{color: red}}>R</span><span style={{color: yellow}}>O</span> <span style={{color: green}}>T</span><span style={{color: orange}}>A</span><span style={{color: blue}}>L</span><span style={{color: red}}>K</span></h1>
                <h2 style={{textAlign: 'center', color: gold, background: goldBackground, padding: '4px', margin: '0 auto', width: '100px', borderRadius: '10px'}}>PLUS</h2>
                <p>Unlimited access to Pro Talk content including cards and book. Gain access to all Pro Talk team survey results and insights. Create an unlimited number of survey groups and view a full history of any survey you have taken before. Also gain access to all future updates and content including Pro Talk Kids™, Pro Talk Sales™ and more!</p>
                <a className="final-submit-btn green-btn" href="/pricing" target="blank">Upgrade Now</a>
              </div>
            </Overlay>
          )}
    </div>
  );
}

export default MySurveys;