import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";
import "../App.css";
import { auth, db } from "../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";

function Dashboard() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const [offset, setOffset] = useState(0);
  const navigate = useNavigate();

  const red = "#C65447";
  const redBackground = "#F4DDDA";
  const green = "#509E6F";
  const greenBackground = "#DCECE2";
  const blue = "#1961A8";
  const blueBackground = "#D1DFEE";
  const yellow = "#FFC337";
  const orange = "#ED6742";
  const gold = "#F0B52C";
  const goldBackground = "#FCF0D5";

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setName(data.name);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };
  function showComingSoon() {
    alert("Single Player Game Coming Soon")
  }; 

  function getCard() {
    return {id: 5, name: "Eye Roll", detail: "I’d like you to know how stupid I think you are, but I am too arrogant to use words."}
  }
  useEffect(() => {
    const onScroll = () => setOffset(window.scrollY);
        // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, [user, loading]);
  return (
    <div className="full-page-container" style={{overflow: 'visible'}}>
      <div style={{marginTop: '48px'}}>
        <h4 className="dashboard-tag-center">YOUR NEW WORKPLACE TOOLKIT</h4>
        <h1 className="dashboard-section-title">Improve your team's communication with a <span style={{color: '#268fff'}}>F</span><span style={{color: red}}>U</span><span style={{color: yellow}}>N</span>, interactive game!</h1>
        <p className="dashboard-section-subtitle">Amplify communication skills and learn to recognize the most common workplace communication challenges that may hinder your team's creativity and productivity.</p>
        <img style={{objectFit: "contain", width: '100%', maxHeight: '300px'}} src={require(`../assets/images/dash2.png`)} />
        <div style={{display: 'grid'}}>
          <a className="final-submit-btn blue-btn-dash" style={{margin: '48px auto auto auto', width: '165px', paddingLeft: '32px'}} href="https://protalk.game" target="blank">Play Now</a>
        </div>
      </div>
      {/* <div style={{display: 'flex', margin: '64px 0'}}>
        <ProTalkCard 
          key={8}
          type={"CHALLENGE"}
          card={getCard()}
          color={red}
          blur={0}
          rotation={-Math.min(offset * 0.02, 25)}
          left={-Math.min(offset * 0.1, 150)}
          onClick={() => {}} />
        <ProTalkCard 
          key={8}
          type={"CHALLENGE"}
          card={getCard()}
          color={red}
          blur={0}
          rotation={-Math.min(offset * 0.002, 20)}
          left={-Math.min(offset * 0.1, 150)}
          onClick={() => {}} />
      </div> */}
      <div style={{marginTop: '48px', display: 'flex', alignItems: 'center'}}>
        <div>
          <h4 className="dashboard-tag-left">DIGITAL COMPANION</h4>
          <h1 className="dashboard-section-title" style={{textAlign: 'left'}}>Real time feedback from your employees</h1>
          <p className="dashboard-section-subtitle" style={{textAlign: 'left'}}>A personalized dashboard that can enable you to swiftly listen to your employees. Identify your highest priorities and enable immediate actions.</p>
        </div>
        <div style={{display: 'grid'}}>
          <img style={{objectFit: "contain", height: '25vw'}} src={require(`../assets/images/39.png`)} />
          <a className="final-submit-btn red-btn-dash" style={{margin: '48px auto auto auto', width: 'auto'}} href="/my-surveys">Learn More</a>
        </div>
      </div>
      <div style={{marginTop: '80px'}}>
        <h4 className="dashboard-tag-center">DISCOVER SOMETHING NEW</h4>
        <h1 className="dashboard-section-title">Do you need <span style={{color: '#268fff'}}>P</span><span style={{color: red}}>R</span><span style={{color: yellow}}>O</span> <span style={{color: green}}>T</span><span style={{color: orange}}>A</span><span style={{color: '#268fff'}}>L</span><span style={{color: red}}>K</span>?</h1>
        <p className="dashboard-section-subtitle">With just 10 questions you can see where you and your team's communication could benefit from using Pro Talk with your team</p>
        <img style={{objectFit: "contain", height: '30vw', width: '100%'}} src={require(`../assets/images/16.png`)} />
        <div style={{display: 'grid'}}>
          <a className="final-submit-btn green-btn-dash" style={{margin: '24px auto', width: '150px', paddingLeft: '32px', paddingRight: '32px'}} href="/survey">Take the survey</a>
        </div>
      </div>
    </div>
  );
}
export default Dashboard;