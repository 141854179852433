import React from 'react';
import { FaChevronUp } from 'react-icons/fa';
import "../App.css";

class RoundedRectangle extends React.Component {
    render() {
      const { circleHorizontalPosition, borderColor, circleColor, shadowColor, triangleColor, backgroundColor } = this.props;
  
      const rectangleStyle = {
        width: '100%', // Adjust the width as needed
        height: '25px', // Adjust the height as needed
        borderRadius: '14px',
        border: `2px solid ${borderColor || 'red'}`, // Default to red if no borderColor prop is provided
        backgroundColor: `${backgroundColor || '#FFD0D0'}`, // Light red interior
        position: 'relative',
        boxShadow: `0px 0px 10px ${shadowColor || 'rgba(0, 0, 0, 0.0)'}`, // Default to a light shadow if no shadowColor prop is provided
      };
  
      const circleStyle = {
        width: '22px', // Adjust the circle size as needed
        height: '22px',
        borderRadius: '50%',
        backgroundColor: circleColor || 'black', // Default to black if no circleColor prop is provided
        position: 'absolute',
        top: '50%',
        left: `${Math.min((circleHorizontalPosition / 10) * 100, 98)}%`,
        transform: 'translate(-50%, -50%)',
      };
  
      const triangleStyle = {
        width: '0',
        height: '0',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderBottom: `20px solid ${triangleColor || 'green'}`, // Default to green if no triangleColor prop is provided
        position: 'absolute',
        top: '115%',
        left: `${Math.min((circleHorizontalPosition / 10) * 100, 98)}%`,
        transform: 'translateX(-50%)',
      };

      const chevronStyle = {
        width: 'auto',
        height: 'auto',
        position: 'absolute',
        top: '110%',
        fontFamily: 'Brandon Grotesque',
        fontSize: '24px',
        color: `${triangleColor}`,
        left: `${Math.min((circleHorizontalPosition / 10) * 100, 98)}%`,
        transform: 'translateX(-50%)',
      };

      const valueStyle = {
        width: 'auto',
        height: 'auto',
        position: 'absolute',
        top: '100%',
        fontFamily: 'Brandon Grotesque',
        fontSize: '24px',
        color: 'white',
        left: `${Math.min((circleHorizontalPosition / 10) * 100, 98)}%`,
        transform: 'translateX(-50%)',
      };
  
      return (
        <div style={rectangleStyle}>
          <div style={circleStyle}></div>
          <FaChevronUp  style={chevronStyle} />
          <p style={valueStyle}>{Number(circleHorizontalPosition).toFixed(1)}</p>
        </div>
      );
    }
  }
  
  export default RoundedRectangle;