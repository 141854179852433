import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "./TeamSurvey.css";
import "../App.css";
import { auth, db } from "../firebase";
import { collection, addDoc } from "firebase/firestore";

function TeamSurvey() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const [teamCode, setTeamCode] = useState("");
  const teamName = urlSearchParams.get("teamName");

  const [user, loading] = useAuthState(auth);

  const [question1Value, setQuestion1Value] = useState(0);
  const [question2Value, setQuestion2Value] = useState(0);
  const [question3Value, setQuestion3Value] = useState(0);
  const [question4Value, setQuestion4Value] = useState(0);
  const [question5Value, setQuestion5Value] = useState(0);
  const [question6Value, setQuestion6Value] = useState(0);
  const [question7Value, setQuestion7Value] = useState(0);
  const [question8Value, setQuestion8Value] = useState(0);
  const [question9Value, setQuestion9Value] = useState(0);
  const [question10Value, setQuestion10Value] = useState(0);
  const navigate = useNavigate();
  const showSurveyScore = (score, superpower) => navigate(`/survey-result?score=${score}&superpower=${superpower}`);

  function calculateScore() {
    if (question1Value === 0 || question2Value === 0 || question3Value === 0 || question4Value === 0 || question5Value === 0 || question6Value === 0 || question7Value === 0 || question8Value === 0 || question9Value === 0 || question10Value === 0) {
      alert("You must answer all questions before you can complete your survey");
      return
    }
    const total = Number(question1Value) + Number(question2Value) + Number(question3Value) + Number(question4Value) + Number(question5Value) + Number(question6Value) + Number(question7Value) + Number(question8Value) + Number(question9Value) + Number(question10Value)

    var superpower = 0
    const highestvalueIndex = indexOfMax([question1Value, question2Value, question3Value, question4Value, question5Value, question6Value, question7Value, question8Value, question9Value, question10Value])
    if (highestvalueIndex === 0 || highestvalueIndex === 5) {
      superpower = 37
      addSurveyScore(total, superpower);
      showSurveyScore(total, superpower);
    } else if (highestvalueIndex === 1 || highestvalueIndex === 6) {
      superpower = 38
      addSurveyScore(total, superpower);
      showSurveyScore(total, superpower);
    } else if (highestvalueIndex === 2 || highestvalueIndex === 7) {
      superpower = 51
      addSurveyScore(total, superpower);
      showSurveyScore(total, superpower);
    } else if (highestvalueIndex === 3 || highestvalueIndex === 8) {
      superpower = 55
      addSurveyScore(total, superpower);
      showSurveyScore(total, superpower);
    } else if (highestvalueIndex === 4 || highestvalueIndex === 9) {
      superpower = 57
      addSurveyScore(total, superpower);
      showSurveyScore(total, superpower);
    } else {
      superpower = 37
      addSurveyScore(total, superpower);
      showSurveyScore(total, superpower);
    }
  };

  function indexOfMax(arr) {
    if (arr.length === 0) {
        return -1;
    }

    var max = arr[0];
    var maxIndex = 0;

    for (var i = 1; i < arr.length; i++) {
        if (arr[i] > max) {
            maxIndex = i;
            max = arr[i];
        }
    }

    return maxIndex;
  };

  function addSurveyScore(score, power) {
    const collectionRef = collection(db, 'surveys');
    let currentDate = new Date();
    let formattedDate = currentDate.toLocaleString();
    const newSurveyData = {
      userId: user?.uid ?? "",
      score: score,
      completionDate: formattedDate,
      question1Value: question1Value,
      question2Value: question2Value,
      question3Value: question3Value,
      question4Value: question4Value,
      question5Value: question5Value,
      question6Value: question6Value,
      question7Value: question7Value,
      question8Value: question8Value,
      question9Value: question9Value,
      question10Value: question10Value,
      teamCode: teamCode ?? null,
      superpower: power ?? null
    };
    addDoc(collectionRef, newSurveyData).then(() => {
      console.log('Document added successfully!');
    }).catch((error) => {
      console.log('Error adding document:', error);
    });
  };

  function setQuestionValueTo(question, value) {
    if (question === 1) {
      setQuestion1Value(value);
    } else if (question === 2) {
      setQuestion2Value(value);
    } else if (question === 3) {
      setQuestion3Value(value);
    } else if (question === 4) {
      setQuestion4Value(value);
    } else if (question === 5) {
      setQuestion5Value(value);
    } else if (question === 6) {
      setQuestion6Value(value);
    } else if (question === 7) {
      setQuestion7Value(value);
    } else if (question === 8) {
      setQuestion8Value(value);
    } else if (question === 9) {
      setQuestion9Value(value);
    } else if (question === 10) {
      setQuestion10Value(value);
    } 
  };
  useEffect(() => {
    if (loading) return;
    const paramCode = urlSearchParams.get("teamCode")
    setTeamCode(paramCode);
    if (teamCode === null) {
      document.getElementById('teamCode').value = '';
    }
  }, [user, loading]);
  return (
    <div className="account" style={{background: 'white'}}>
       <div className="survey-page-container">
       <h1 className="survey-title">TEAM SURVEY</h1> 
       {(user == null)? (
        <a className="inline-btn" style={{marginBottom: '20px', display: 'block'}} href="/account">Log in to save your score</a>
       ) : (
        <p className="survey-subtitle subtitle-text" style={{marginBottom: '8px'}}>Logged in as <b>{user?.displayName}</b></p>
       )}
       {(teamName == null)? (
        <div>
          <input id="teamCode" name="teamCode" placeholder="Group Code" style={{borderRadius: '10px', borderColor: '#f4f4f4', padding: '10px', margin: '20px 0px 0px 0px', fontSize: 'medium', color: '#444444', textTransform: 'uppercase'}} onChange={(e) => { 
            setTeamCode(e.target.value); 
          }}></input>
          <p className="survey-subtitle subtitle-text" style={{fontSize: '11px', paddingTop: '4px'}}>Entering a group code will sync your survey score with your team<br></br>although not to worry, all of your responses will be <i style={{textDecoration: 'underline'}}>recorded anonymously</i>!</p>
          </div>
       ) : (
        <p className="survey-question" style={{marginBottom: '20px'}}>Your survey will be saved and grouped with <i style={{textDecoration: 'underline'}}>{teamName}</i>, although not to worry, all of your responses will be <i style={{textDecoration: 'underline'}}>recorded anonymously</i>!</p>
       )}
       <p className="survey-subtitle subtitle-text" style={{paddingTop: '24px'}}>For all of your answers <b>1 = Strongly Disagree</b> & <b>10 means Strongly Agree</b></p>
       <br></br>
       <h3 className="survey-question">My co-workers listen to my questions and ideas.</h3>
       <div className="survey-container">
       <a className={`gray-btn${question1Value === 1 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(1, 1)}>1</a>
       <a className={`gray-btn${question1Value === 2 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(1, 2)}>2</a>
       <a className={`gray-btn${question1Value === 3 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(1, 3)}>3</a>
       <a className={`gray-btn${question1Value === 4 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(1, 4)}>4</a>
       <a className={`gray-btn${question1Value === 5 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(1, 5)}>5</a>
       <a className={`gray-btn${question1Value === 6 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(1, 6)}>6</a>
       <a className={`gray-btn${question1Value === 7 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(1, 7)}>7</a>
       <a className={`gray-btn${question1Value === 8 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(1, 8)}>8</a>
       <a className={`gray-btn${question1Value === 9 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(1, 9)}>9</a>
       <a className={`gray-btn${question1Value === 10 ? "-active" : ""} submit-btn`} onClick={() => setQuestionValueTo(1, 10)}>10</a>
       </div>
        <h3 className="survey-question">When I don't understand something at work, I feel comfortable asking for clarification.</h3>
        <div className="survey-container">
       <a className={`gray-btn${question2Value === 1 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(2, 1)}>1</a>
       <a className={`gray-btn${question2Value === 2 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(2, 2)}>2</a>
       <a className={`gray-btn${question2Value === 3 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(2, 3)}>3</a>
       <a className={`gray-btn${question2Value === 4 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(2, 4)}>4</a>
       <a className={`gray-btn${question2Value === 5 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(2, 5)}>5</a>
       <a className={`gray-btn${question2Value === 6 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(2, 6)}>6</a>
       <a className={`gray-btn${question2Value === 7 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(2, 7)}>7</a>
       <a className={`gray-btn${question2Value === 8 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(2, 8)}>8</a>
       <a className={`gray-btn${question2Value === 9 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(2, 9)}>9</a>
       <a className={`gray-btn${question2Value === 10 ? "-active" : ""} submit-btn`} onClick={() => setQuestionValueTo(2, 10)}>10</a>
       </div>
        <h3 className="survey-question">In work related matters, I feel safe to speak my mind.</h3>
        <div className="survey-container">
       <a className={`gray-btn${question3Value === 1 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(3, 1)}>1</a>
       <a className={`gray-btn${question3Value === 2 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(3, 2)}>2</a>
       <a className={`gray-btn${question3Value === 3 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(3, 3)}>3</a>
       <a className={`gray-btn${question3Value === 4 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(3, 4)}>4</a>
       <a className={`gray-btn${question3Value === 5 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(3, 5)}>5</a>
       <a className={`gray-btn${question3Value === 6 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(3, 6)}>6</a>
       <a className={`gray-btn${question3Value === 7 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(3, 7)}>7</a>
       <a className={`gray-btn${question3Value === 8 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(3, 8)}>8</a>
       <a className={`gray-btn${question3Value === 9 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(3, 9)}>9</a>
       <a className={`gray-btn${question3Value === 10 ? "-active" : ""} submit-btn`} onClick={() => setQuestionValueTo(3, 10)}>10</a>
       </div>
        <h3 className="survey-question">My co-workers are kind and respectful.</h3>
        <div className="survey-container">
       <a className={`gray-btn${question4Value === 1 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(4, 1)}>1</a>
       <a className={`gray-btn${question4Value === 2 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(4, 2)}>2</a>
       <a className={`gray-btn${question4Value === 3 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(4, 3)}>3</a>
       <a className={`gray-btn${question4Value === 4 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(4, 4)}>4</a>
       <a className={`gray-btn${question4Value === 5 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(4, 5)}>5</a>
       <a className={`gray-btn${question4Value === 6 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(4, 6)}>6</a>
       <a className={`gray-btn${question4Value === 7 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(4, 7)}>7</a>
       <a className={`gray-btn${question4Value === 8 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(4, 8)}>8</a>
       <a className={`gray-btn${question4Value === 9 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(4, 9)}>9</a>
       <a className={`gray-btn${question4Value === 10 ? "-active" : ""} submit-btn`} onClick={() => setQuestionValueTo(4, 10)}>10</a>
       </div>
        <h3 className="survey-question">My co-workers care about how I feel.</h3>
        <div className="survey-container">
       <a className={`gray-btn${question5Value === 1 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(5, 1)}>1</a>
       <a className={`gray-btn${question5Value === 2 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(5, 2)}>2</a>
       <a className={`gray-btn${question5Value === 3 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(5, 3)}>3</a>
       <a className={`gray-btn${question5Value === 4 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(5, 4)}>4</a>
       <a className={`gray-btn${question5Value === 5 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(5, 5)}>5</a>
       <a className={`gray-btn${question5Value === 6 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(5, 6)}>6</a>
       <a className={`gray-btn${question5Value === 7 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(5, 7)}>7</a>
       <a className={`gray-btn${question5Value === 8 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(5, 8)}>8</a>
       <a className={`gray-btn${question5Value === 9 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(5, 9)}>9</a>
       <a className={`gray-btn${question5Value === 10 ? "-active" : ""} submit-btn`} onClick={() => setQuestionValueTo(5, 10)}>10</a>
       </div>
        <h3 className="survey-question">People I speak with give me their full attention.</h3>
        <div className="survey-container">
       <a className={`gray-btn${question6Value === 1 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(6, 1)}>1</a>
       <a className={`gray-btn${question6Value === 2 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(6, 2)}>2</a>
       <a className={`gray-btn${question6Value === 3 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(6, 3)}>3</a>
       <a className={`gray-btn${question6Value === 4 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(6, 4)}>4</a>
       <a className={`gray-btn${question6Value === 5 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(6, 5)}>5</a>
       <a className={`gray-btn${question6Value === 6 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(6, 6)}>6</a>
       <a className={`gray-btn${question6Value === 7 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(6, 7)}>7</a>
       <a className={`gray-btn${question6Value === 8 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(6, 8)}>8</a>
       <a className={`gray-btn${question6Value === 9 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(6, 9)}>9</a>
       <a className={`gray-btn${question6Value === 10 ? "-active" : ""} submit-btn`} onClick={() => setQuestionValueTo(6, 10)}>10</a>
       </div>
        <h3 className="survey-question">Our work climate encourages clarifying questions.</h3>
        <div className="survey-container">
       <a className={`gray-btn${question7Value === 1 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(7, 1)}>1</a>
       <a className={`gray-btn${question7Value === 2 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(7, 2)}>2</a>
       <a className={`gray-btn${question7Value === 3 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(7, 3)}>3</a>
       <a className={`gray-btn${question7Value === 4 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(7, 4)}>4</a>
       <a className={`gray-btn${question7Value === 5 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(7, 5)}>5</a>
       <a className={`gray-btn${question7Value === 6 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(7, 6)}>6</a>
       <a className={`gray-btn${question7Value === 7 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(7, 7)}>7</a>
       <a className={`gray-btn${question7Value === 8 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(7, 8)}>8</a>
       <a className={`gray-btn${question7Value === 9 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(7, 9)}>9</a>
       <a className={`gray-btn${question7Value === 10 ? "-active" : ""} submit-btn`} onClick={() => setQuestionValueTo(7, 10)}>10</a>
       </div>
        <h3 className="survey-question">Our work climate encourages ideas that go against the status quo.</h3>
        <div className="survey-container">
       <a className={`gray-btn${question8Value === 1 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(8, 1)}>1</a>
       <a className={`gray-btn${question8Value === 2 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(8, 2)}>2</a>
       <a className={`gray-btn${question8Value === 3 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(8, 3)}>3</a>
       <a className={`gray-btn${question8Value === 4 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(8, 4)}>4</a>
       <a className={`gray-btn${question8Value === 5 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(8, 5)}>5</a>
       <a className={`gray-btn${question8Value === 6 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(8, 6)}>6</a>
       <a className={`gray-btn${question8Value === 7 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(8, 7)}>7</a>
       <a className={`gray-btn${question8Value === 8 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(8, 8)}>8</a>
       <a className={`gray-btn${question8Value === 9 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(8, 9)}>9</a>
       <a className={`gray-btn${question8Value === 10 ? "-active" : ""} submit-btn`} onClick={() => setQuestionValueTo(8, 10)}>10</a>
       </div>
        <h3 className="survey-question">The people I work with speak respectfully to each other.</h3>
        <div className="survey-container">
       <a className={`gray-btn${question9Value === 1 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(9, 1)}>1</a>
       <a className={`gray-btn${question9Value === 2 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(9, 2)}>2</a>
       <a className={`gray-btn${question9Value === 3 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(9, 3)}>3</a>
       <a className={`gray-btn${question9Value === 4 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(9, 4)}>4</a>
       <a className={`gray-btn${question9Value === 5 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(9, 5)}>5</a>
       <a className={`gray-btn${question9Value === 6 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(9, 6)}>6</a>
       <a className={`gray-btn${question9Value === 7 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(9, 7)}>7</a>
       <a className={`gray-btn${question9Value === 8 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(9, 8)}>8</a>
       <a className={`gray-btn${question9Value === 9 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(9, 9)}>9</a>
       <a className={`gray-btn${question9Value === 10 ? "-active" : ""} submit-btn`} onClick={() => setQuestionValueTo(9, 10)}>10</a>
       </div>
        <h3 className="survey-question">We have an emotionally supportive work climate.</h3>
        <div className="survey-container">
       <a className={`gray-btn${question10Value === 1 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(10, 1)}>1</a>
       <a className={`gray-btn${question10Value === 2 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(10, 2)}>2</a>
       <a className={`gray-btn${question10Value === 3 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(10, 3)}>3</a>
       <a className={`gray-btn${question10Value === 4 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(10, 4)}>4</a>
       <a className={`gray-btn${question10Value === 5 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(10, 5)}>5</a>
       <a className={`gray-btn${question10Value === 6 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(10, 6)}>6</a>
       <a className={`gray-btn${question10Value === 7 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(10, 7)}>7</a>
       <a className={`gray-btn${question10Value === 8 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(10, 8)}>8</a>
       <a className={`gray-btn${question10Value === 9 ? "-active" : ""} submit-btn`} style={{marginRight : '4px'}} onClick={() => setQuestionValueTo(10, 9)}>9</a>
       <a className={`gray-btn${question10Value === 10 ? "-active" : ""} submit-btn`} onClick={() => setQuestionValueTo(10, 10)}>10</a>
       </div>
       <div style={{marginTop: '30px', marginBottom: '100px'}}>
        <a className="final-submit-btn" onClick={calculateScore}>Submit Survey</a>
       </div>
       </div>
     </div>
  );
}
export default TeamSurvey;