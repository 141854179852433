import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import "./Navbar.css";
import { FaBars } from 'react-icons/fa';

const Navbar = () => {

  const [showNavbar, setShowNavbar] = useState(false)

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  }

  const hideNavbar = () => {
    setShowNavbar(false);
  }

  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">
          <a className='segment-item' style={{display:'flex', alignItems: 'center', fontSize: '28px', color: 'black', textDecoration: 'none'}} href='/'><img id="cardimg" style={{objectFit: "contain", height: "40px", marginRight: '6px'}} src={require(`../../assets/images/46.png`)} />PRO TALK</a>
        </div>
        <div className="menu-icon" onClick={handleShowNavbar} style={{paddingRight: '24px'}}>
          <FaBars />
        </div>
        <div className={`nav-elements  ${showNavbar && 'active'}`} style={{background: '#8aa5c0'}}>
          <ul>
            <li>
              <NavLink className={'nav-item'} to="/" onClick={() => {hideNavbar()}}>HOME</NavLink>
            </li>
            <li>
              <NavLink className={'nav-item'} to="https://protalk.game" target='blank' onClick={() => {hideNavbar()}}>PLAY</NavLink>
            </li>
            <li>
              <NavLink className={'nav-item'} to="/resources" onClick={() => {hideNavbar()}}>TOOL KIT</NavLink>
            </li>
            <li>
              <NavLink className={'nav-item'} to="/my-surveys" onClick={() => {hideNavbar()}}>SURVEYS</NavLink>
            </li>
            <li>
              <NavLink className={'nav-item'} to="/my-team" onClick={() => {hideNavbar()}}>MY TEAM</NavLink>
            </li>
            <li>
              <NavLink className={'nav-item'} style={{marginRight: '12px'}} to="/account" onClick={() => {hideNavbar()}}>ACCOUNT</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar