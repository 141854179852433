import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "./Survey.css";
import "../App.css";
import { auth, db } from "../firebase";
import { query, collection, getDocs, where, doc, getDoc } from "firebase/firestore";

function SurveyResult() {
  const [user, loading] = useAuthState(auth);
//   const [name, setName] = useState("");
  const urlSearchParams = new URLSearchParams(window.location.search);
  const score = urlSearchParams.get("score");
  const superPower = urlSearchParams.get("superpower") ?? 37;
  var [currentMeaning, setCurrentMeaning] = useState("Waiting");
  var [superpowerTitle, setSuperpowerTitle] = useState("");
  var [superpowerDetail, setSuperpowerDetail] = useState("");
  const navigate = useNavigate();
  const handleStartSurvey = () => navigate("/survey");

  const getSuperpowerTitle = async () => {
    console.log(`SUPERPOWER: ${superPower}`)
    const docRef = doc(db, "solutions", `${superPower}`);
    const docSnap = await getDoc(docRef);
    console.log(docSnap);
    const superpowerCard = docSnap.data();
    setSuperpowerTitle(superpowerCard.name.toUpperCase());
    setSuperpowerDetail(`${superpowerCard.whyGoodPoints.join(`\n`)}`);
  }

  useEffect(() => {
    if (loading) return;
    // if (!user) return navigate("/");
    // fetchUserName();
    getSuperpowerTitle();
  }, [user, loading]);
  return (
    <div className="page-container">
      <h1 className="survey-title" style={{fontSize: '24px', paddingTop: '24px', textAlign: "center", color: 'white'}}>YOUR SURVEY SCORE</h1>
      <h1 className="survey-score" style={{marginTop: '0px', marginBottom: '0px', color: 'white'}}>{score}</h1>
      <div style={{marginTop: '32px', display: 'flex', alignItems: 'center'}}>
        <div style={{marginRight: '16px'}}>
          <h4 className="dashboard-tag-left">TEAM SUPERPOWER</h4>
          <h1 className="dashboard-section-title" style={{textAlign: 'left', fontSize: '32px'}}>{superpowerTitle}</h1>
          <p className="dashboard-section-subtitle" style={{textAlign: 'left'}}>{superpowerDetail}</p>
        </div>
        <div style={{display: 'grid'}}>
          <img style={{objectFit: "contain", height: '25vw'}} src={require(`../assets/images/${superPower}.png`)} />
          {/* <a className="final-submit-btn blue-btn-dash" style={{margin: '48px auto auto auto'}} href="/survey">Take Survey</a> */}
        </div>
      </div>
      <div style={{marginTop: '32px', display: 'flex', alignItems: 'center'}}>
      <div style={{display: 'grid'}}>
          <img style={{objectFit: "contain", height: '25vw'}} src={require(`../assets/images/57.png`)} />
        </div>
        <div style={{marginLeft: '16px', marginTop: '64px', marginBottom: '64px'}}>
          <h4 className="dashboard-tag-right">LOOKING FOR MORE?</h4>
          <h1 className="dashboard-section-title" style={{textAlign: 'right', fontSize: '32px'}}>Want to improve your score?</h1>
          <p className="dashboard-section-subtitle" style={{textAlign: 'right'}}>The Pro Talk Toolkit can offer insights into a variaty of communication challenges and solutions.</p>
          <a className="final-submit-btn red-btn-dash" style={{margin: '12px 0 0 auto', width: '60%'}} href="https://protalkgame.com/products/pro-talk-tool-kit" target="blank">Buy Pro Talk</a>
        </div>
      </div>
      <div style={{marginTop: '32px', marginBottom: '24px'}}>
        {/* <a className="final-submit-btn green-btn-dash" style={{marginTop: '16px'}} href={"https://www.protalkgame.com"} target="_blank">Share Survey Results</a> */}
        <a className="final-submit-btn blue-btn-dash" style={{marginTop: '16px', marginBottom: '48px'}} onClick={handleStartSurvey}>Start New Quick Survey</a>
      </div>
    </div>
  );
}

export default SurveyResult;